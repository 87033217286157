<template>
  <div
    :class="[
      'credit-reward',
      `credit-reward_${type}`
    ]"
    :style="styles"
  >
    <div class="credit-reward__value">
      {{ benefitInfo.bgImageText }}
    </div>
    <div class="credit-reward__label">
      {{ benefitInfo.bgImageSuffixText }}
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
const props = defineProps({
  benefitInfo: {
    type: Object,
    default: () => {}
  },
  type: {
    type: String,
    default: '1' // 0: 空车 1: 非空车
  }
})

const styles = computed(() => {
  return {
    '--bg-image': `url(${props.type == '0' ? props.benefitInfo?.bgImageWithShadow || '' : props.benefitInfo?.bgImage || ''})`
  }
})
</script>

<style lang="less" scoped>
.credit-reward {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 5/37.5rem 6/37.5rem;
  width: 57/37.5rem;
  height: 32/37.5rem;
  text-align: center;
  background-image: var(--bg-image);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  .credit-reward__value {
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    background-image: linear-gradient(180deg, #FFEECD 57.81%, #FBDA99 100%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    text-shadow: 0px 0.9px 0.6px 0.1px #193725;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .credit-reward__label {
    width: 100%;
    font-size: 8px;
    font-weight: bold;
    background-image: linear-gradient(181deg, #FFF 28.92%, #FFDF8C 88.12%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    text-shadow: 0px 0.9px 0.6px 0.1px #193725;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.credit-reward_0 {
  padding: 9/37.5rem 12/37.5rem 15/37.5rem;
  width: 104/37.5rem;
  height: 60/37.5rem;
  .credit-reward__value {
    font-size: 20px;

  }
  .credit-reward__label {
    font-size: 14px;
  }
}
</style>
