export const EMPTY_CART_PAGE_FROM = 'empty_cart_button'

export const ADD_ON_ITEM_SA_INFO_MAP = {
  'freeShipping': {
    activity_from: 'shipping_add',
    state: 'shipping_add',
  },
  'returnCoupon': {
    activity_from: 'promotion_add',
    state: 'promotion_add',
  },
}

// 购物车使用bff接口开关 最新到cart_v4,下一期到cart_v5
export const USE_BFF_API_KEYS = [
]

export const CART_CLICK_UN_PAID_ORDER_INFO = 'cart_click_unpaid_order_info'
