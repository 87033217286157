var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
    id: '1-8-7-65',
    data: {
      order_id: _setup.billno,
      benefit_list: _setup.savedPrice ? 'discount' : 'common',
    }
  }),expression:"{\n    id: '1-8-7-65',\n    data: {\n      order_id: billno,\n      benefit_list: savedPrice ? 'discount' : 'common',\n    }\n  }"},{name:"tap",rawName:"v-tap",value:(_setup.getClickSaData('body')),expression:"getClickSaData('body')"}],staticClass:"cart-unpaid-tip",on:{"click":_setup.handleViewOrder}},[(_setup.imgList.length)?_c(_setup.imgStackGallery,{attrs:{"img-list":_setup.imgList,"count":_setup.totalNums,"locals":_vm.locals}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"unpaid-content"},[_c('div',{staticClass:"content-up"},[_c('span',{staticClass:"unpaid-tip"},[_vm._v(_vm._s(_vm.language.SHEIN_KEY_PWA_26690))]),_vm._v(" "),(_setup.showCountDown)?_c('div',{staticClass:"unpaid-countdown"},[(_setup.isShowNewCountDown)?[_c(_setup.CountDown,{attrs:{"timeStamp":_vm.order.expireCountdown,"styleConfig":{
              '--font-color': '#fff',
              '--num-bg-color': '#FA6338',
              '--divider-color': '#FA6338',
              '--divider-font-weight': 'bold'
            }}})]:[_c(_setup.sui_icon_hourglass_12px_2,{attrs:{"size":"12px","color":"#FA6338"}}),_vm._v(" "),_c('count-down-time',{staticClass:"unpaid-order__count-down",attrs:{"time-stamp":_vm.order.expireCountdown,"notReload":"","notHide":"","font-size":"10px"}})]],2):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"content-down"},[_c('span',{staticClass:"order-total"},[_c('span',{staticClass:"order-amount-tip"},[_vm._v(_vm._s(_vm.language.SHEIN_KEY_PWA_30842))]),_vm._v(" "),_c('span',{staticClass:"order-amount"},[_vm._v(_vm._s(': ' + _setup.total))])]),_vm._v(" "),(_setup.savedPrice)?_c('span',{staticClass:"order-saved"},[_c('span',{staticClass:"order-split-line"},[_vm._v("|")]),_vm._v(" "),_c('span',{staticClass:"order-saved-tip order-amount-tip"},[_vm._v(_vm._s(_vm.language.SHEIN_KEY_PWA_30913))]),_vm._v(" "),_c('em',{staticClass:"order-saved-price order-amount"},[_vm._v(_vm._s(': ' + _setup.savedPrice))])]):_vm._e()])]),_vm._v(" "),_c('div',{directives:[{name:"tap",rawName:"v-tap",value:(_setup.getClickSaData('view')),expression:"getClickSaData('view')"}],staticClass:"view-button",on:{"click":function($event){$event.stopPropagation();return _setup.handleViewOrder.apply(null, arguments)}}},[_c('span',{staticClass:"button-text"},[_vm._v(_vm._s(_vm.language.SHEIN_KEY_PWA_18965))]),_vm._v(" "),_c(_setup.sui_icon_more_right_12px_2,{attrs:{"size":"12px","color":"#fff","is-rotate":_vm.locals.GB_cssRight}})],1),_vm._v(" "),_c('div',{directives:[{name:"tap",rawName:"v-tap",value:(_setup.getClickSaData('close')),expression:"getClickSaData('close')"}],staticClass:"close-button",on:{"click":function($event){$event.stopPropagation();return _setup.handleClose.apply(null, arguments)}}},[_c(_setup.sui_icon_close_16px_2,{attrs:{"size":"16px","color":"#959595"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }