var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.isShow)?_c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
    id: '1-8-7-65',
    data: {
      order_id: _setup.billno,
      benefit_list: _setup.savedPrice ? 'discount' : 'common',
    }
  }),expression:"{\n    id: '1-8-7-65',\n    data: {\n      order_id: billno,\n      benefit_list: savedPrice ? 'discount' : 'common',\n    }\n  }"},{name:"tap",rawName:"v-tap",value:(_setup.getClickSaData('body')),expression:"getClickSaData('body')"}],staticClass:"cu-order",on:{"click":_setup.onClick}},[_c(_setup.BenefitInfo,{attrs:{"order-info":_vm.orderInfo,"language":_vm.language,"type":"1"},on:{"viewOrder":_setup.onClickView}}),_vm._v(" "),_c(_setup.sui_icon_close_16px_2,{staticClass:"cu-order__close",attrs:{"size":"16px","color":"#959595"},on:{"click":function($event){$event.stopPropagation();return _setup.onClickClose.apply(null, arguments)}}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }