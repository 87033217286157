<template>
  <div>
    <div
      class="cart-item__stepper-new"
      :class="{ 'disabled': batchActive }"
    >
      <i
        v-show="showIcon"
        v-enterkey
        class="stepper-icon stepper-icon_minus"
        :tabindex="batchActive ? -1 : 0"
        role="button"
        :aria-label="language.SHEIN_KEY_PWA_17957"
        @touchstart.stop.prevent="quantity > 1 ? handleQuantity('reduce', $event) : handleDelete('reduce')"
      >
        <component
          :is="minusIconName"
          size="16px"
        ></component>
      </i>
      <input
        v-expose="exposeStepperNum()"
        lang="en"
        name="item_qty"
        :type="inputType"
        pattern="[0-9]*"
        min="1"
        max="99"
        class="stepper-num"
        :class="{
          disabled: soldoutIds.includes(item.id),
          'hide-icon': !showIcon,
        }"
        :value="inputVal"
        :size="inputVal.length + 1"
        :tabindex="batchActive ? -1 : 0"
        :aria-label="language.SHEIN_KEY_PWA_17958"
        :disabled="soldoutIds.includes(item.id) || item.isNewcomerItem"
        :readonly="batchActive || item.isNewcomerItem"
        @click="handleClick"
        @change="handleQuantity('input', $event)"
      />
      <i
        v-show="showIcon"
        v-enterkey
        class="stepper-icon stepper-icon_add"
        :style="{
          color: disabledAdd ? '#BBB' : '#222'
        }"
        :tabindex="batchActive ? -1 : 0"
        :aria-label="language.SHEIN_KEY_PWA_17959"
        role="button"
        @touchstart.stop.prevent="disabledAdd ? '' : handleQuantity('add', $event)"
      >
        <sui_icon_add_16px size="16px" />
      </i>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { updateCartItem } from '../../utils/fetcher'
import { template } from '@shein/common-function'
import { cartEmphasizePromotionAll } from '../../utils'
import { daEventCenter } from '../../../../services/eventCenter'
import { sui_icon_add_16px, sui_icon_min_16px, sui_icon_delete_18px } from '@shein-aidc/icon-vue2'

let { langPath } = gbCommonInfo
export default {
  name: 'CartStepper',
  components: {
    sui_icon_add_16px,
    sui_icon_min_16px,
    sui_icon_delete_18px,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    disabledAdd: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    ...mapState('cart', ['language', 'soldoutIds', 'batchActive', 'integrityPromotionInfo', 
      'showIncentiveProductListDialog', 
      'monitorUserInteraction',
      'incentivePopoverContent',
      'isShowCartFloorDrawer',
    ]),
    ...mapGetters('cart', ['cartList']),
    quantity() {
      const { quantity, quantity_by_local } = this.item
      return typeof quantity_by_lo3cal === 'number' ? quantity_by_local : quantity
    },
    showIcon() {
      return true
    },
    minusIconName() {
      return this.quantity == 1 ? `sui_icon_delete_18px` : `sui_icon_min_16px`
    },
    inputType() {
      return this.showIcon ? 'number' : 'text'
    },
    inputVal() {
      return this.showIcon ? this.quantity : `x${this.quantity}`
    },
    incentiveScene() {
      if (this.isShowCartFloorDrawer) return 'cart_floor_benefit_pop'
      if (this.showIncentiveProductListDialog) return this.incentivePopoverContent?.type === 'credit_reward' ? 'clubpop' : 'benefit_pop'
      return 'cart'
    }
  },
  methods: {
    ...mapMutations('cart', [ 'updateState', 'toggleDrawer', 'showLoading', 'hideLoading', 'modifyCheckedBeforeLoading', 'handleClearIsCheckedByLocal']),
    ...mapActions('cart', [ 'asyncUpdateCartInfo', 'fetchModifyCartCheckStatus', 'handleFlashSaleOverScene']),
    verifyIsNewcomerItem() {
      const { language, item, integrityPromotionInfo } = this
      if (item.isNewcomerItem) {
        const target = item.product?.product_promotion_info?.find?.(f => f.type_id == '31')
        const num = integrityPromotionInfo?.[target?.promotion_id]?.cart_limit?.single
        if (num) {
          this.$toast(language.SHEIN_KEY_PWA_26184.replace('{0}', num))
          daEventCenter.triggerNotice({ daId: '1-8-3-33' })
        }
        return true
      }
      return false
    },
    handleDelete(type) {
      if (this.verifyIsNewcomerItem()) return
      this.$emit('handleDelete', type)
    },
    handleClick(){
      if (this.batchActive || this.verifyIsNewcomerItem()) return false
      daEventCenter.triggerNotice({
        daId: '1-8-3-1',
        extraData: {
          scene: this.incentiveScene
        }
      })
    },
    handleQuantity(type, ev) {
      if (this.batchActive || this.verifyIsNewcomerItem()) return false
      if ( type == 'add' && this.disabledAdd ) return false

      const old_quantity = parseInt(this.quantity)
      let quantity = parseInt(this.quantity)

      if ( type == 'add' ) {
        if ( quantity >= 99 ) return
        quantity++
      } else if ( type == 'reduce' ) {
        if ( quantity <= 1 ) return
        quantity--
      } else if ( type == 'input' ) {
        quantity = parseInt($(ev.target).val())
        if ( isNaN(quantity) ) quantity = 1
        if ( quantity <= 0 ) {
          quantity = 1
        } else {
          if ( quantity > 99 ) {
            this.item.quantity = old_quantity
            $(ev.target).val(old_quantity)
            return this.$toast(template('99', this.language.SHEIN_KEY_PWA_17829))
          }
        }
      }
      // 商品告罄时，减少商品直接减少到库存数量
      if((quantity >= parseInt(this.item.realTimeInventory))){
        quantity = this.item.realTimeInventory
      }

      // this.item.quantity = quantity  // 意外的 Vuex 更新，导致购物车优惠券差额接口调用，此时 update 尚未调用，引发竞态
      $(ev.target).val(quantity)

      this.showLoading()
      // 在接口返回之前，先修改本地商品的选中状态
      this.modifyCheckedBeforeLoading({
        operation_type: 1,
        cart_id_list: [this.item.id]
      })
      // 在接口返回之前，先修改本地商品的数量
      this.modifyQuantityBeforeLoading(quantity)
      updateCartItem({ quantity, id: this.item.id })
        .then(res => {
          if (res.code != 0) {
            // 接口错误，恢复原来的数量
            this.handleRestoreData(old_quantity)
          }
          this.hideLoading()
          this.handleQtyResponse({ res, type, old_quantity })
          this.toggleDrawer({ type: 'coupon-helper', isUpdateHelperOnly: true })
          // 当编辑商品数量时，停止低效用户监测
          this.monitorUserInteraction.stop({ reason: 'change goods number' })
        })
        .catch(err => {
          this.hideLoading()
          if (err.code === 'ERR_CANCELED') return
          // 接口错误，恢复原来的数量
          this.handleRestoreData(old_quantity)
        })
    },
    modifyQuantityBeforeLoading(quantity = undefined) {
      this.$set(this.item, 'quantity_by_local', quantity)
    },
    handleRestoreData(oldQuantity) {
      this.item.quantity = oldQuantity
      this.modifyQuantityBeforeLoading()
      this.handleClearIsCheckedByLocal({ operation_type: 2, cart_id_list: [this.item.id] })
    },
    async handleQtyResponse({ res, type, old_quantity = 0 }) {
      const item = this.item
      // 接入 apollo 控制是否调用 check 接口
      if (res.code == 0 && res.CART_AUTO_CHECKOUT !== 'ON') {
        let reqParam = {
          operation_type: 1,
          cart_id_list: []
        }
        if(item.is_checked == 0) {
          reqParam.cart_id_list.push(item.id)
          reqParam.editCartId = item.id
        }
        reqParam.cart_id_list = reqParam.cart_id_list.concat(item.appendIds || [])
        reqParam.cart_id_list.length && this.fetchModifyCartCheckStatus(reqParam)
      }

      [ 'add', 'reduce' ].includes(type) && daEventCenter.triggerNotice({
        daId: '1-8-7-5',
        extraData: {
          type: type,
          value: res.code == 0 && res.info ? 1 : 0
        }
      })

      if ( res.code == 0 && res.info ) {
        if (type === 'add') daEventCenter.triggerNotice({
          daId: '1-8-3-39',
          extraData: {
            scene: this.incentiveScene,
          }
        })
        if (type === 'reduce') {
          daEventCenter.triggerNotice({ 
            daId: '1-8-3-40',
            extraData: {
              qty: this.quantity,
              goods_type: 'main',
              scene: this.incentiveScene,
            }
          })
        }
        let originCarts = JSON.parse(JSON.stringify(this.cartList))
        await this.asyncUpdateCartInfo({ cartInfo: res.info.info })
        this.handleFlashSaleOverScene({ editCartId: item.id, carts: res.info.info?.carts, originCarts: originCarts })
      } else {
        item.quantity = old_quantity

        if ( res.code == 300206 ) {
          window.location = langPath + '/user/logout'
        } else if ( res.code == 300402 ) {
          this.$toast(this.language.SHEIN_KEY_PWA_15230)
        } else if ( [ 200401, 500301, 500302, 500306, 300417, 302444, 300714 ].includes(+res.code) ) {
          let errTxt = this.language.SHEIN_KEY_PWA_14967
          let p_id = ''
          if ( res.info && res.info.limitCount ) {
            errTxt = this.language.SHEIN_KEY_PWA_16138.replace('{0}', res.info.limitCount)
          }
          if ( [ 300714, 500302, 500306, 302444, 300417 ].includes(+res.code) ) {
            errTxt = res.tips.replace('{0}', res?.info?.limitCount)
            // let proInfo = item.product.product_promotion_info && item.product.product_promotion_info.filter(promo => promo.type_id == 10 || promo.type_id == 11)
            p_id = this.item.promotionGroupId
            daEventCenter.triggerNotice({
              daId: '1-8-1-5'
            })
          }
          if([ 500302, 500306, 300417 ].includes(+res.code)) {
            errTxt = template(res?.info?.limitCount, res?.info?.resultLimit, res?.info?.remainNum, res.tips)
            this.$toast(errTxt, 4000)
          } else {
            this.showWarningInfo( {
              text: errTxt,
              cb() {
                if ( p_id ) cartEmphasizePromotionAll( [ p_id ], {
                  shake: true,
                  changeColor: true,
                  scroll: true
                })
              }
            })
          }
        } else if ( res.code == 500303 ) {
          // let proInfo = item.product.product_promotion_info && item.product.product_promotion_info.filter(promo => promo.type_id == 10 || promo.type_id == 11)
          const p_id = this.item.promotionGroupId
          this.showWarningInfo( {
            text: template(res?.info?.limitCount, res?.info?.resultLimit, res?.info?.remainNum, res.tips),
            cb() {
              if ( p_id ) cartEmphasizePromotionAll( [ p_id ], {
                shake: true,
                changeColor: true,
                scroll: true
              })
              daEventCenter.triggerNotice({
                daId: '1-8-1-5'
              })
            }
          })
        } else if ( res.code == 500304 || res.code == 500305 ) {
          // 打折限购
          const proInfo = item.product.product_promotion_info && item.product.product_promotion_info.filter(promo => promo.typeId == 24)
          const id = proInfo[0] && +proInfo[0].promotion_id
          cartEmphasizePromotionAll( [ id ], { shake: true, changeColor: true, scroll: true })
        } else if ( res.code == 300405 ) {
          this.showWarningInfo( { text: res.info.limit_desc.replace('N', res.info.limit_count) })
        } else if ( res.code == 300470 ) {
          let errTxt = res.tips.replace('{0}', res.info?.single_row_capacity_limit)
          this.$toast(errTxt)
        } else {
          this.$toast(res.tips)
        }
      }
    },
    showWarningInfo({ text = '', cb }){
      this.$toast(text)
      setTimeout(()=>{
        cb && cb()
      }, 1500)
    },
    exposeStepperNum() {
      if (this.showIcon) return
      return {
        id: '1-8-3-52',
        data: {
          goods_id: this.item?.product?.goods_id,
          cart_id: this.item?.id,
        }
      }
    },
  }
}
</script>

<style lang="less" scoped>
.cart-item__stepper-new {
  box-sizing: content-box;
  height: 44/75rem;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  align-items: center;
  &.disabled {
    opacity: .3;
  }

  .stepper-icon {
    background: #F6F6F6;
    line-height: 44/75rem;
    padding: 0 3/37.5rem;
    min-width: auto;
    font-size: 16px !important;/* stylelint-disable-line declaration-no-important */
    &_add {
      border-left: 1px solid #e5e5e5;
    }
    &_minus {
      border-right: 1px solid #e5e5e5;
    }
  }

  .stepper-num {
    height: 22/37.5rem;
    line-height: 22/37.5rem;
    margin: 0;
    border: none;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    -moz-appearance: textfield;
    min-width: 26/37.5rem;
    &::selection {
      background-color: #fff;
      color: #222;
    }
    &.disabled {
      color: #999;
      background: #fff;
    }
    &.hide-icon {
      min-width: 22/37.5rem;
    }
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
</style>
