<template>
  <s-checkbox
    :value="checkAllItem"
    label="all"
    :disabled="isDisabledCheckedAll || doFilter || promoHubVisible || showPromotionInterceptionDialog"
    @change="handleChange"
  >
    {{ language.SHEIN_KEY_PWA_15623 }}
  </s-checkbox>
</template>

<script>
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { mapState, mapGetters, mapActions } from 'vuex'
import { web_modify_check_click, web_modify_check_response } from '../utils/metricTagsConfig.js'

/**
 * @description 购物车/结算BAR/全选小组件
 */
export default {
  name: 'CartCheckoutAll',

  props: {
    updateCheckedItemIds: {
      type: Function,
      required: true
    },
  },

  computed: {
    ...mapGetters('cart', ['carts', 'doFilter']),
    ...mapState('cart', ['language', 'locals', 'soldoutIds', 'isCheckedByLocal', 'showDiscountDetails', 'showIncentiveProductListDialog', 'promoHubVisible', 'showPromotionInterceptionDialog']),
    isDisabledCheckedAll(){
      let normalItems = this.carts?.filter(item => !this.soldoutIds.includes(item.id)) || [] // 过滤购物车内所有告罄商品
      if(this.showIncentiveProductListDialog) return true
      return normalItems.length ? false : true
    },
    checkAllItem(){
      if(this.isDisabledCheckedAll) return false
      let normalItems = this.carts?.filter(item => !this.soldoutIds.includes(item.id)) || [] // 购物车内所有告罄商品
      return normalItems.filter(item => {
        // 优先使用存在本地缓存的选中状态
        return typeof this.isCheckedByLocal[item.id] === 'number' ? this.isCheckedByLocal[item.id] === 0 : item.is_checked == 0
      }).length ? false : true
    },
  },

  methods: {
    ...mapActions('cart', ['fetchModifyCartCheckStatus']),
    async handleChange(){
      let isAllChecked = this.checkAllItem
      web_modify_check_click(isAllChecked ? 0 : 1, 'all')
      let reqParam = {
        operation_type: isAllChecked ? 4 : 3,
      }
      daEventCenter.triggerNotice({
        daId: '1-8-3-8',
        extraData: {
          is_select: isAllChecked ? 1 : 0,
        }
      })
      let code = await this.fetchModifyCartCheckStatus(reqParam)
      web_modify_check_response(isAllChecked ? 0 : 1, 'all', code == 0 ? 1 : 0)

      if(this.checkAllItem && this.showDiscountDetails) {
        this.updateCheckedItemIds()
      }
    },
  }
}
</script>
