<template>
  <div
    v-if="shippingTip"
    class="shipping-header-container"
    da-expose-code="cart-self-shop-shipping"
  >
    <div
      class="shipping-header"
      :da-event-expose="shippingInfo.isFirstFreeShipping ? '' : shippingInfo.isLadder ? '1-8-5-5' : '1-8-5-3'"
      :data-origin_price="shippingAnalysisParam.origin_price"
      :data-shipping_method="shippingAnalysisParam.shipping_method"
      :data-is_fullshippingactivity="shippingAnalysisParam.is_fullshippingactivity"
      :data-step_fee="shippingAnalysisParam.step_fee"
      :data-num_price="shippingAnalysisParam.num_price"
      :data-charging_type="shippingAnalysisParam.charging_type"
      :data-shipping-free-type="'3'"
      :data-mall_code="mallCode"
    >
      <sui_icon_shipping_18px
        class="item-content-icon"
        size="18px"
      />
      <div class="shipping-content">
        <span
          class="shipping-tip"
          v-html="shippingTip"
        ></span>
        <count-down-time
          v-if="firstFreeShipping.showCountDown && firstFreeShipping.countDownTime > 0"
          :time-stamp="firstFreeShipping.countDownTime"
          :is-left-time="true"
        />
      </div>
      <span
        v-if="shippingInfo.showShipSupplement"
        class="shipping-operation"
        role="button"
        tabindex="0"
        da-event-expose="1-8-7-11"
        da-event-click="1-8-7-12"
        :data-shipping_free_type="shippingInfo.isFirstFreeShipping ? '' : '3'"
        :data-mall_code="mallCode"
        @keydown.stop.enter="clickCartAdd"
        @click="clickCartAdd"
      >
        {{ language.SHEIN_KEY_PWA_15091 }}
        <sui_icon_more_right_12px
          size="12px"
          :is-rotate="locals.GB_cssRight"
        />
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { sui_icon_more_right_12px, sui_icon_shipping_18px } from '@shein-aidc/icon-vue2'
import { daEventCenter } from 'public/src/services/eventCenter'
daEventCenter.addSubscriber({ modulecode: '1-8-7' })
daEventCenter.addSubscriber({ modulecode: '1-8-5' })

export default {
  name: 'PlatformShipping',
  components: {
    sui_icon_more_right_12px,
    sui_icon_shipping_18px,
  },
  props: {
    sortKey: {
      type: String,
      default: ''
    },
    mallCode: {
      type: String,
      default: ''
    }
  },
  data(){
    return {
      daEventExpose: null
    }
  },
  computed: {
    ...mapState('cart', ['language', 'mallShippingInfo', 'cartInfo', 'isPlatForm', 'locals']),
    ...mapGetters('cart', ['mallShippingAnalysisParam', 'singleMallCode', 'carts']),
    shippingInfo(){
      return this.mallShippingInfo[this.mallCode] || {}
    },
    firstFreeShipping() {
      return this.shippingInfo.isFirstFreeShipping ? this.shippingInfo.firstFreeShipping : {}
    },
    shippingTip() {
      return this.firstFreeShipping.firstFreeShippingTip || this.shippingInfo.shippingTip
    },
    shippingAnalysisParam() {
      return this.mallShippingAnalysisParam[this.mallCode] || {}
    },
  },
  watch: {
    '$route' () {
      this.initExpose()
    },
    'shippingInfo.showShipSupplement'(n){
      if(n){
        this.initExpose()
      }
    }
  },
  mounted() {
    this.initExpose()
  },
  methods: {
    ...mapMutations('cart', [ 'updateState' ]),
    clickCartAdd: function (){

      daEventCenter.triggerNotice({
        daId: this.shippingInfo.isLadder ? '1-8-5-6' : '1-8-5-4',
        extraData: {
          ...this.shippingAnalysisParam,
          mall_code: this.mallCode
        }
      })

      const addType = this.firstFreeShipping.firstFreeShippingTip ? 998 : 999
      // 判断abt走新的免邮凑单凑单弹窗
      this.updateState({ key: 'freeShippingAddOnType', value: this.mallCode })
      this.updateState({ key: 'isMoreMallFreeShippingAddOn', value: true })
      this.openToAddOn({
        promotion_id: addType,
      })
    },
    initExpose() {
      if(this.daEventExpose) {
        this.daEventExpose.reset('cart-self-shop-shipping')
        this.$nextTick(() => {
          this.daEventExpose.update('cart-self-shop-shipping')
        })
        return
      }
      this.daEventExpose = daEventCenter.getExposeInstance()
      this.$nextTick(()=>{
        this.daEventExpose.subscribe({
          keycode: 'cart-self-shop-shipping`1-8-7-11|1-8-5-5|1-8-5-3',
        })
      })

    },
    openToAddOn(promotion) {
      if (!promotion || !promotion.promotion_id) return
      this.updateState({
        key: 'addOnInfo', 
        value: {
          show: true,
          promotionId: promotion.promotion_id,
        }
      })
    }
  }
}
</script>

<style lang="less">
.shipping-header-container{
  background-color: #FFFFFF;
  .shipping-header{
    color: #222222;
    display: flex;
    background: @sui_color_safety_bg;
    padding: 20/75rem;
    align-items: center;
    justify-content: space-between;
    border-bottom: .5px solid #E5E5E5;
  }
  .shipping-tip{
    flex: 1;
    font-size: 12px;
    line-height: 1.5;
    word-break: break-word;
    strong{
      font-weight: 400;
      color: @sui_color_highlight;
    }
  }
  .shipping-operation{
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
  }
  .shipping-content{
    flex: 1;
    padding: 0 20/75rem 0;
  }
}
</style>
