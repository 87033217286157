<template>
  <span
    class="repick"
    @click="handleRepick"
  >
    <Icon
      class="repick-icon"
      name="sui_icon_change_16px"
      size="12px"
      color="#000"
    />
    <span
      ref="repickText"
      class="repick-text"
      :class="{ 'hidden-text': hiddenText }"
    >{{ language.SHEIN_KEY_PWA_32885 }}</span>
  </span>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'CartItemRepick',
  props: {
    item: {
      type: Object,
      default: (() => {})
    }
  },
  data() {
    return {
      hiddenText: false,
    }
  },
  computed: {
    ...mapState('cart', ['integrityPromotionInfo', 'language']),
    promotionId() {
      return this.item?.promotionTag?.promotionId
    },
    promotionTypeId() {
      return this.item?.promotionTag?.typeId
    },
    promotionInfo() {
      return this.integrityPromotionInfo[this.promotionId]
    }
  },
  mounted() {
    this.$nextTick(() => {
      const textDom = this.$refs.repickText
      if (textDom.scrollWidth > textDom.clientWidth) {
        this.hiddenText = true
      }
    })
  },
  methods: {
    ...mapMutations('cart', ['updateState', 'changeAppendageDrawerStatus']),
    handleRepick() {
      // 'addBuyShow'   13加价购、全场加价购
      // 'fullGiftShow' 4全场满赠、28满赠
      // 'buyGiftShow'  2买赠
      let status
      if ([13, 22].includes(this.promotionTypeId)) {
        status = 'addBuyShow'
      } else if ([4, 28].includes(this.promotionTypeId)) {
        status = 'fullGiftShow'
      } else if ([2].includes(this.promotionTypeId)) {
        status = 'buyGiftShow'
      }
      if (!status) return
      this.changeAppendageDrawerStatus({ 
        status,
        promotionId: this.promotionId
      })
    }
  },
}
</script>

<style lang="less" scoped>
.repick {
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
  max-width: 142/75rem;
  height: 36/75rem;
  border: solid 1/75rem #BBBBBB;
  border-radius: 24/75rem;
  background: #fff;
  color: #000;
  font-size: 24/75rem;
  padding: 4/75rem 16/75rem;
  overflow: hidden;
  .repick-icon {
    flex-shrink: 0;
  }
  .repick-text {
    flex-shrink: 1;
    margin-left: 8/75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.hidden-text {
      display: none
    }
  }
}
</style>
