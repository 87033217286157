import { render, staticRenderFns } from "./NewFreeShip.vue?vue&type=template&id=9647ee12&scoped=true&"
import script from "./NewFreeShip.vue?vue&type=script&lang=js&"
export * from "./NewFreeShip.vue?vue&type=script&lang=js&"
import style0 from "./NewFreeShip.vue?vue&type=style&index=0&id=9647ee12&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.10.0_css-loader@6.8.1_ejs@2.7.2_lodash@4.17.21_vue-template-compiler@2.7.14_webpack@5.86.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9647ee12",
  null
  
)

export default component.exports