<template>
  <div class="mcart mcart-empty opt-bg">
    <img
      class="fsp-element opt-bg-img"
      fsp-key="cart_empty_icon"
      src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAcHBwcIBwgJCQgMDAsMDBEQDg4QERoSFBIUEhonGB0YGB0YJyMqIiAiKiM+MSsrMT5IPDk8SFdOTldtaG2Pj8ABBwcHBwgHCAkJCAwMCwwMERAODhARGhIUEhQSGicYHRgYHRgnIyoiICIqIz4xKysxPkg8OTxIV05OV21obY+PwP/CABEIARICwAMBIgACEQEDEQH/xAAVAAEBAAAAAAAAAAAAAAAAAAAACP/aAAgBAQAAAACkQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAH//xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oACAECEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAP/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAIAQMQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA/8QAFBABAAAAAAAAAAAAAAAAAAAAsP/aAAgBAQABPwBAj//EABQRAQAAAAAAAAAAAAAAAAAAAJD/2gAIAQIBAT8ARj//xAAUEQEAAAAAAAAAAAAAAAAAAACQ/9oACAEDAQE/AEY//9k="
    />
    <div class="page-inner">
      <div class="img-box">
        <component
          :is="IS_RW ? 'sui_img_nodata_bag_75px' : 'sui_icon_empty_cart_75px_color'"
          size="75px"
          :color="IS_RW ? '#fc4070' : '#666666'"
        />
      </div>
      <div
        v-if="loginStatus"
        class="grey-txt margin-top-32"
        tabindex="0"
      >
        {{ language.SHEIN_KEY_PWA_16049 }}
      </div>
      <template v-else>
        <p
          class="margin-top-32"
          tabindex="0"
        >
          {{ language.SHEIN_KEY_PWA_17376 }}
        </p>
        <div
          class="grey-txt"
          tabindex="0"
        >
          {{ language.SHEIN_KEY_PWA_17379 }}
        </div>
      </template>
    </div>
    <!-- 未登录 -->
    <div
      v-if="!loginStatus"
      class="empty-cart-btn"
    >
      <s-button
        v-enterkey
        :type="['primary', 'H72PX']"
        da-event-click="1-8-1-8"
        role="button"
        tabindex="0"
        @click="clickLogin"
      >
        {{ language.SHEIN_KEY_PWA_17087 }}
      </s-button>
      <br />
    </div>
    <slot></slot>
    <div
      v-if="(loginStatus && showGoshoppingBtn && IS_RW) || !loginStatus"
      class="empty-cart-btn"
    >
      <s-button
        v-enterkey
        :type="[ (!loginStatus ? '' : 'primary'), 'H72PX']"
        da-event-click="1-8-1-7"
        role="button"
        tabindex="0"
        @click="goShopping"
      >
        {{ language.SHEIN_KEY_PWA_15659 }}
      </s-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { markPoint } from 'public/src/services/mark/index.js'
import { sui_icon_empty_cart_75px_color, sui_img_nodata_bag_75px } from '@shein-aidc/icon-vue2'

const { langPath } = gbCommonInfo
export default {
  name: 'CartEmpty',
  components: {
    sui_icon_empty_cart_75px_color,
    sui_img_nodata_bag_75px
  },
  computed: {
    ...mapState('cart', ['loginStatus', 'language', 'locals']),
    ...mapGetters('cart', ['showGoshoppingBtn']),
    IMG_LINK () {
      return this.locals.IMG_LINK
    },
    IS_RW () {
      return this.locals.IS_RW
    }
  },
  methods: {
    goShopping: async function () {
      markPoint('toNextPageClick', 'public')
      let { transformPromodiscountUrl } = await import('public/src/pages/common/biz_helper/shein_picks')
      this.$routerPush(transformPromodiscountUrl(`${langPath}/shein-picks.html`))
    },
    clickLogin: function () {
      this.$emit('clickLogin', 'empty')
    }
  }
}
</script>

<style lang="less">
.mcart-empty {
  background: #fff;
  padding-bottom: 64/75rem;
  position: relative;

  .empty-cart-btn {
    margin: .32rem;

    button {
      width: 100%;
      text-transform: uppercase;
    }
  }
}

.page-inner {
  padding: 120/75rem 0 0rem;
  min-height: 4.03rem;
  text-align: center;

  .img-box{
    width: 2rem;
    height: 2rem;
    background-color: #FFFFFF;
    margin: 0 auto;
    position: relative;
  }


  .grey-txt {
    .font-dpr(32px);
    font-weight: 800;
    color: #222;
    margin-top: 0.32rem;
  }
}
.mcart-empty.opt-bg{
  // background-image: url(data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAcHBwcIBwgJCQgMDAsMDBEQDg4QERoSFBIUEhonGB0YGB0YJyMqIiAiKiM+MSsrMT5IPDk8SFdOTldtaG2Pj8ABBwcHBwgHCAkJCAwMCwwMERAODhARGhIUEhQSGicYHRgYHRgnIyoiICIqIz4xKysxPkg8OTxIV05OV21obY+PwP/CABEIARICwAMBIgACEQEDEQH/xAAVAAEBAAAAAAAAAAAAAAAAAAAACP/aAAgBAQAAAACkQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAH//xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oACAECEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAP/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAIAQMQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA/8QAFBABAAAAAAAAAAAAAAAAAAAAsP/aAAgBAQABPwBAj//EABQRAQAAAAAAAAAAAAAAAAAAAJD/2gAIAQIBAT8ARj//xAAUEQEAAAAAAAAAAAAAAAAAAACQ/9oACAEDAQE/AEY//9k=);
  .opt-bg-img{
    position: absolute;
    height: 4rem;
    width: 100%;
    z-index: -1;/* stylelint-disable-line declaration-property-value-blacklist */
  }
}
</style>
