<template>
  <div
    v-if="isShow"
    v-expose="{
      id: '1-8-7-65',
      data: {
        order_id: billno,
        benefit_list: savedPrice ? 'discount' : 'common',
      }
    }"
    v-tap="getClickSaData('body')"
    class="cu-order"
    @click="onClick"
  >
    <BenefitInfo
      :order-info="orderInfo"
      :language="language"
      type="1"
      @viewOrder="onClickView"
    />
    <sui_icon_close_16px_2
      class="cu-order__close"
      size="16px"
      color="#959595"
      @click.stop="onClickClose"
    />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { sui_icon_close_16px_2 } from '@shein-aidc/icon-vue2'
import BenefitInfo from './BenefitInfo.vue'
import { daEventCenter } from '@/public/src/services/eventCenter'

const props = defineProps({
  orderInfo: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  }
})

const billno = computed(() => props.orderInfo?.billno || '')
const savedPrice = computed(() => {
  if(props.orderInfo?.savedPrice?.amount <= 0) return ''
  return props.orderInfo?.savedPrice?.amountWithSymbol
})
const emit = defineEmits('viewOrder')

const isShow = ref(true)
const onClickClose = () => {
  isShow.value = false
  daEventCenter.triggerNotice({
    daId: '1-8-7-66',
    extraData: {
      order_id: props.orderInfo?.billno,
      benefit_list: savedPrice.value ? 'discount' : 'common',
      button_type: 'close'
    }
  })
}
const onClick = () => {
  emit('viewOrder', { type: props.type, orderInfo: props.orderInfo })
}
const onClickView = () => {
  daEventCenter.triggerNotice({
    daId: '1-8-7-66',
    extraData: {
      order_id: props.orderInfo?.billno,
      benefit_list: savedPrice.value ? 'discount' : 'common',
      button_type: 'view'
    }
  })
  emit('viewOrder', { type: props.type, orderInfo: props.orderInfo })
}
const getClickSaData = (type = '') => {
  return {
    id: '1-8-7-66',
    data: {
      order_id: props.orderInfo?.billno,
      benefit_list: savedPrice.value ? 'discount' : 'common',
      button_type: type || 'body'
    }
  }
}
</script>

<style lang="less" scoped>
.cu-order {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6/37.5rem 12/37.5rem;
  width: 100%;
  height: 44/37.5rem;
  background: linear-gradient(90deg, #FFECE9 2.73%, #FFFAF9 100%);
  .cu-order__close {
    flex-shrink: 0;
    margin-left: 10/37.5rem;
  }
}
</style>
