var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"enterkey",rawName:"v-enterkey"}],class:[
      'free-ship',
      'new-free-ship',
      _vm.oldRightsFuns ? 'old-rights-funs' : ''
    ],on:{"click":_vm.clickCartTip}},[(!_vm.oldRightsFuns)?[_vm._t("default",function(){return [_c('i',{staticClass:"suiiconfont item-icon",class:[!_vm.showShipSupplement ? 'sui_icon_selected_16px' : 'sui_icon_shipping_green_18px sui_icon_shipping_18px'],attrs:{"aria-hidden":"true"}})]}),_vm._v(" "),(_vm.freeShippinpTips)?_c('div',{staticClass:"ship-content"},[_c('div',{staticClass:"tips",domProps:{"innerHTML":_vm._s(_vm.freeShippinpTips)}}),_vm._v(" "),(_vm.showCountDown && _vm.countDownTime > 0)?_c('div',{class:{'float-count-down': _vm.floatCountDown}},[_c('ClientOnly',[_c('count-down',{attrs:{"is-left-time":true,"time-stamp":_vm.countDownTime,"type":_vm.countDownType}})],1)],1):_vm._e()]):_vm._e()]:[_c('div',{staticClass:"icon-tip-wrap"},[_vm._t("default",function(){return [_c('Icon',{staticClass:"item-icon",attrs:{"is-responsive-name":"","name":!_vm.showShipSupplement ? 'sui_icon_selected_16px': 'sui_icon_freeshipping_16px_color',"size":"16px","color":"#0E8C33"}})]}),_vm._v(" "),(_vm.freeShippinpTips)?_c('div',{staticClass:"ship-content"},[_c('div',{staticClass:"tips",domProps:{"innerHTML":_vm._s(_vm.freeShippinpTips)}}),_vm._v(" "),(_vm.showCountDown && _vm.countDownTime > 0)?_c('div',{class:{'float-count-down': _vm.floatCountDown}},[_c('ClientOnly',[_c('count-down',{attrs:{"is-left-time":true,"time-stamp":_vm.countDownTime,"type":_vm.countDownType}})],1)],1):_vm._e()]):_vm._e()],2)],_vm._v(" "),(_vm.showShipSupplement)?_c('a',{class:[
        'cart-tip__operation',
        _vm.oldRightsFuns ? 'cart-tip__operation_new' : ''
      ],attrs:{"tabindex":"0","aria-label":_vm.language.SHEIN_KEY_PWA_15091}},[_c('span',{class:{ 'free-shipping-button': _vm.oldRightsFuns }},[_vm._v("\n        "+_vm._s(_vm.language.SHEIN_KEY_PWA_15091)+"\n      ")]),_vm._v(" "),_c('sui_icon_more_right_12px',{attrs:{"size":"12px","is-rotate":_vm.locals.GB_cssRight}})],1):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }