<template>
  <div class="cart-bar-bnpl">
    <div class="cart-bar-bnpl__top">
      <div class="cart-bar-bnpl__all">
        <CartCheckoutAll :update-checked-item-ids="updateCheckedItemIds" />
      </div>
      <div class="cart-bar-bnpl__other">
        <div class="cart-bar-bnpl__num">
          <span>{{ language.SHEIN_KEY_PWA_30068 }}</span>{{ checkedQuantity }}
        </div>
        <AutoStretchSwiperTag
          class="cart-bar-bnpl__swiper-tag"
          :show-free-shipping-tag="showNewFreeShipping"
          :show-quick-ship-tag="showQuickShip"
          :language="language"
          :show="show"
          :theme-vars="{
            '--border-radius': '2px',
          }"
        />
      </div>
      <div class="cart-bar-bnpl__price">
        <CartCheckoutPrice
          :is-opened="!!showDiscountDetails"
          @click="handleDiscountDetails(!showDiscountDetails, 'click-save')"
        />
      </div>
    </div>
    <div class="cart-bar-bnpl__bottom">
      <div class="cart-bar-bnpl__btn">
        <s-button
          v-if="payShowStyleA"
          v-tpes:cart
          v-expose="{ id: '1-8-1-42'}"
          :type="['default','H80PX']"
          :width="362/75+'rem'"
          :limit-duration="10"
          tabindex="0"
          role="button"
          @pointerover.native="prefetchCheckout(1, paymentFrontPaymentCode)"
          @pointerdown.native="prefetchCheckout(2, paymentFrontPaymentCode)"
          @click.native.stop="clickCheckout({
            paymentCode: paymentFrontPaymentCode,
          })"
        >
          <span class="cart-bar-bnpl__with">
            <span class="cart-bar-bnpl__with-text">
              <span class="text">{{ language.SHEIN_KEY_PWA_30070 }}</span>
              <img
                :src="paymentFrontRecommendPayment.logoUrl"
                class="icon"
              />
            </span>
            <span class="cart-bar-bnpl__with-price">
              <AmountRoll
                :style="{ fontSize: fontSize }"
                :class="['j-installmentPrice', 'price', !isSuggested && 'discount']"
                :amount="periodAmountFormat"
              />
              <span class="today">{{ language.SHEIN_KEY_PWA_30069 }}</span>
            </span>
          </span>
        </s-button>
        <div
          v-else
          id="paypal-button"
          class="cart-paypal-btn"
        ></div>
      </div>
      <div class="cart-bar-bnpl__btn">
        <CartCheckoutButtonPopover
          :show-filter-label-popover.sync="showFilterLabelPopover"
        >
          <s-button
            v-tpes:cart
            :type="['primary','H80PX']"
            :limit-duration="10"
            width="100%"
            tabindex="1"
            role="button"
            @pointerover.native="prefetchCheckout(1)"
            @pointerdown.native="prefetchCheckout(2)"
            @click.native.stop="clickCheckout"
          >
            <span class="cart-bar-bnpl__checkout">
              <span class="text">
                {{ controlCheckoutNameText ? checkoutNameText : language.SHEIN_KEY_PWA_15062 }}
              </span>
              <AmountRoll
                :style="{ fontSize: fontSize }"
                :class="['j-normalPrice', 'price', !isSuggested && 'discount']"
                :amount="totalSalePrice"
              />
            </span>
          </s-button>
        </CartCheckoutButtonPopover>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import CartCheckoutAll from './CartCheckoutAll.vue'
import CartCheckoutPrice from './CartCheckoutPrice.vue'
import CartCheckoutButtonPopover from './CartCheckoutButtonPopover.vue'
import AutoStretchSwiperTag from './AutoStretchSwiperTag.vue'
import AmountRoll from './AmountRoll.vue'

/**
 * @description 购物车/结算BAR/BNPL结算
 */
export default {
  name: 'CartCheckoutBarBNPL',

  components: {
    CartCheckoutAll,
    CartCheckoutPrice,
    CartCheckoutButtonPopover,
    AutoStretchSwiperTag,
    AmountRoll,
  },

  props: {
    updateCheckedItemIds: {
      type: Function,
      required: true
    },
    handleDiscountDetails: {
      type: Function,
      required: true,
    },
    totalPriceAmountWithSymbol: {
      type: String,
      required: true
    },
    isShowDiscountDetailsEntry: {
      type: Boolean,
      required: true,
    },
    showFilterLabelPopover: {
      type: String,
      required: true,
    },
    prefetchCheckout: {
      type: Function,
      required: true,
    },
    fontSize: {
      type: String,
      required: true,
    },
    checkoutNameText: {
      type: String,
      required: true,
    },
    checkedQuantity: {
      type: null,
      required: true,
    },
    controlCheckoutNameText: {
      type: null,
      required: true,
    },
    showNewFreeShipping: {
      type: Boolean,
      required: true,
    },
    clickCheckout: {
      type: Function,
      required: true,
    },
    language: {
      type: Object,
      required: true,
    },
    showQuickShip: {
      type: [Boolean, String],
      default: false,
    },
    show: { // 是否展示出来了
      type: Boolean,
      default: false
    },
    payShowStyleA: {
      type: [Boolean, String],
      default: false,
    },
  },

  computed: {
    ...mapGetters('cart', ['paymentFrontRecommendPayment', 'paymentFrontPaymentCode', 'isSuggested']),
    ...mapState('cart', ['cartInfo', 'paymentFrontShowForCartIndexInfo', 'locals', 'showDiscountDetails']),
    periodAmountFormat() {
      return this.paymentFrontShowForCartIndexInfo.periodAmountFormat ?? {}
    },
    // 购物总价
    totalSalePrice() {
      if (this.isSuggested) {
        return this.cartInfo.total_price_before_coupon
      } else {
        return this.cartInfo.totalPrice
      }
    },
  }
}
</script>

<style lang="less">
.cart-bar-bnpl {
  display: flex;
  flex-direction: column;
  background-color: #fff;

  &__top {
    display: flex;
    align-items: center;
    min-height: 40/37.5rem;
  }
  &__all {
    font-size: 0;
  }
  &__other {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 8/37.5rem;
    &:after {
      position: absolute;
      height: 20/37.5rem;
      width: 1px;
      left: -8/37.5rem;
      top: 50%;
      transform: translateY(-50%);
      background-color: #EAEAEA;
      content: "";
    }
  }
  &__num {
    display: flex;
    width: 93/37.5rem;
    color: @sui_color_main;
    .font-dpr(24px);
    line-height: 1;
    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      min-width: 0;
      padding-right: 2/37.5rem;
    }
  }
  &__swiper-tag {
    margin-top: 4/37.5rem;
    border-radius: 2/37.5rem;
    .font-dpr(20px);
  }
  &__price {
    margin-left: auto;
    display: inline-block;
    overflow: hidden;

    .cart-checkout__price-wrapper {
      flex: 1;
      overflow: hidden;
      max-width: 100%;
    }
  }
  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 8/37.5rem;
  }
  &__btn {
    flex-shrink: 0;
    width: calc(50% - 0.10666667rem);
    .S-button {
      padding-left: 8/37.5rem;
      padding-right: 8/37.5rem;
    }
    .S-button.S-button__default {
      border-color: #000;
    }
  }
  &__btn:first-child {
    margin-right: 4/37.5rem;
  }
  &__btn:last-child {
    margin-left: 4/37.5rem;
  }
  &__with {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-weight: normal;
    width: calc(100% - 0.42666667rem);
    margin: 0 auto;
    
  }
  &__with-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .text {
      padding-right: 4/37.5rem;
      .font-dpr(22px);
      line-height: 14/37.5rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      min-width: 0;
      color: #000;
    }
    .icon {
      width: 22/37.5rem;
      height: 14/37.5rem;
      flex-shrink: 0;
      object-fit: contain;
    }
  }
  &__with-price {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 17/37.5rem;
    line-height: 17/37.5rem;
    .price {
      display: flex;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      min-width: 0;
      font-weight: bold;
      font-size: 14px;
      text-align: left;
      .price-amount-decimal {
        white-space: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        max-width: 100%;
        word-break: break-all;
      }
    }
    .price-currency {
      .font-dpr(20px);
    }
    .discount {
      color: #FA6338;
    }
    .today {
      color: #767676;
      padding-left: 4/37.5rem;
    }
  }
  &__checkout {
    display: flex;
    flex-direction: column;
    font-weight: normal;
    width: calc(100% - 0.42666667rem);
    margin: 0 auto;
    align-items: center;
    .text {
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-transform: uppercase;
      line-height: 1;
      .font-dpr(22px);
    }
    .price {
      display: flex;
      height: 17/37.5rem;
      line-height: 17/37.5rem;
      font-weight: bold;
      .font-dpr(28px);
      overflow: hidden;
      text-align: left;
      .price-amount-decimal {
        white-space: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        max-width: 100%;
        word-break: break-all;
      }
    }
    .discount {
      color: #FA6338;
    }
    .price-currency {
      .font-dpr(20px);
    }
  }
  .cart-paypal-btn{
    height: 80/75rem;
    background: #ffc439;
    display: inline-flex;
    align-items: center;
    width: 100%;
  }
}
</style>
