<template>
  <div
    :class="[
      'new-user-gifts',
      `new-user-gifts_${type}`
    ]"
  >
    <div class="new-user-gifts__img-list">
      <div
        v-for="(item, i) in imageList"
        :key="i"
        class="new-user-gifts__img-box"
        :style="{ width: item.size, height: item.size, zIndex: item.zIndex, '--margin-left': imgGaps[i] }"
      >
        <img
          :src="item.imageUrl"
          alt=""
          class="new-user-gifts__img"
        />
        <div
          v-if="item.imageBelt && item.imageBelt.text"
          class="new-user-gifts__belt"
          :style="getBeltStyle(item.imageBelt)"
        >
          {{ item.imageBelt.text }}
        </div>
      </div>
    </div>
    <div
      v-if="badgeValue"
      class="new-user-gifts__badge"
    >
      {{ badgeValue }}
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { hexToRGBA } from '@/public/src/pages/cartNew/utils/index.js'

const props = defineProps({
  benefitInfo: {
    type: Object,
    default: () => {}
  },
  type: {
    type: String,
    default: '1' // 0: 空车 1: 非空车
  }
})
const imgGaps = computed(() => {
  if (props.type === '0') return [0, `-${33 / 37.5}rem`, `-${30 / 37.5}rem`]
  return [0, `-${20 / 37.5}rem`, `-${18 / 37.5}rem`]
})
const imageList = computed(() => {
  return props.benefitInfo?.imageList?.slice(0, 3).map((img, index) => {
    return {
      imageUrl: img,
      imageBelt: index === 0 ? props.benefitInfo?.imageBelt || null : null,
      size: props.type === '0' ? `${((50 - index * 6) / 37.5).toFixed(5)}rem` : `${((32 - index * 4) / 37.5).toFixed(5)}rem`,
      zIndex: 3 - index
    }
  })
})
const badgeValue = computed(() => {
  return props.benefitInfo?.imageList?.length > 1 ? `+${props.benefitInfo.imageList.length}` : ''
})

const getBeltStyle = (imageBelt) => {
  return {
    '--bg-color': hexToRGBA(imageBelt.bgColor, imageBelt.bgColorAlpha ),
    '--text-color': imageBelt.textColor,
  }
}
</script>

<style lang="less" scoped>
.new-user-gifts {
  position: relative;
  .new-user-gifts__img-list {
    display: inline-flex;
    align-items: center;
  }
  .new-user-gifts__img-box {
    position: relative;
    border-radius: 6/37.5rem;
    overflow: hidden;
    box-shadow: 0 0 0 1px #fff;
    margin-left: var(--margin-left);
    .new-user-gifts__img {
      width: 100%;
      height: 100%;
    }
    .new-user-gifts__belt {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 10/37.5rem;
      line-height: 10/37.5rem;
      text-align: center;
      font-size: 8px;
      background: var(--bg-color, #FA6338);
      color: var(--text-color, #fff);
    }
  }
  .new-user-gifts__badge {
    position: absolute;
    top: -4/37.5rem;
    left: 25/37.5rem;
    font-size: 7px;
    line-height: 8px;
    padding: 0 2.5/37.5rem;
    background: #FA6338;
    color: #fff;
    z-index: 4;
    border-radius: 4/37.5rem 4/37.5rem 4/37.5rem 0;
  }
}

.new-user-gifts_0 {
  .new-user-gifts__img-box {
    border-radius: 10/37.5rem;
    .new-user-gifts__belt {
      width: 100%;
      height: 16/37.5rem;
      line-height: 16/37.5rem;
      text-align: center;
      font-size: 12px;
      background: var(--bg-color, #FA6338);
      color: var(--text-color, #fff);
    }
  }
  .new-user-gifts__badge {
    position: absolute;
    top: -4/37.5rem;
    left: 40/37.5rem;
    font-size: 11px;
    line-height: 11px;
    padding: 0 3.5/37.5rem;
    border-radius: 13/37.5rem 13/37.5rem 13/37.5rem 0;
  }
}
</style>
