<template>
  <div class="custom-good-container">
    <div
      class="custom-good-entry"
      @click="onOpen"
    >
      <div>{{ language.SHEIN_KEY_PWA_26444 }}</div>
      <sui_icon_more_right_12px
        class="shop-icon_more"
        size="12px"
        :is-rotate="locals.GB_cssRight"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import customDetailDialog from 'public/src/pages/common/customDetailDialog/index.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { sui_icon_more_right_12px } from '@shein-aidc/icon-vue2'

export default {
  name: 'CustomDetailEntry',
  components: {
    sui_icon_more_right_12px,
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      showDetail: false
    }
  },
  computed: {
    ...mapState('cart', ['language', 'locals']),
    customInfo() {
      return this.item.product?.customization_info?.preview || {}
    }
  },
  async mounted() {
  },
  methods: {
    onOpen() {
      customDetailDialog.open({
        ...this.customInfo,
        onEvent: {
          close: () => this.onClose()
        }
      })
      daEventCenter.triggerNotice({
        daId: '1-8-7-63',
        extraData: {
          images: this.customInfo.images?.length || 0,
          preview: this.customInfo.effects?.length || 0,
          texts: this.customInfo.texts?.length || 0,
        }
      })
    },
    onClose(val) {
      console.log(val)
    }
  },
}
</script>

<style scoped lang="less">
.custom-good-container {
  padding: 0 20/75rem 20/75rem 80/75rem;
}
.custom-good-entry {
  display: flex;
  justify-content: space-between;
  background: #f6f6f6;
  color: #222;
  font-size: 12px;
  padding: 12/75rem 8/75rem 12/75rem 12/75rem;
}
</style>
