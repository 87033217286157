<template>
  <div
    class="free-gift-tip"
    @click="handleFreeGift"
  >
    <!-- 全场满赠/加价购活动（typeid 4/22）展示凑单文案及入口 -->
    <template v-if="[4, 22].includes(promotionTypeId)">
      <span
        class="tip-text"
        v-html="promotionTip"
      ></span>
      <Icon
        v-if="!batchActive"
        class="icon"
        name="sui_icon_more_right_16px"
        size="14px"
      />
    </template>
    <!-- 非全场买赠/满赠活动/加价购（typeid 2/28/13）展示静态文案 -->
    <span
      v-if="[2, 28, 13].includes(promotionTypeId)"
      class="tip-text emphasize"
    >
      {{ language.SHEIN_KEY_PWA_33922 }}
    </span>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'CartItemGiftTip',
  props: {
    item: {
      type: Object,
      default: (() => {})
    },
    batchActive: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapState('cart', ['integrityPromotionInfo', 'language']),
    promotionId() {
      return this.item?.promotionTag?.promotionId
    },
    promotionTypeId() {
      return this.item?.promotionTag?.typeId
    },
    promotionInfo() {
      return this.integrityPromotionInfo[this.promotionId]
    },
    promotionTip() {
      const promotionTip = this.promotionInfo?.content?.tip 
      if (typeof promotionTip == 'object' && promotionTip !== null) {
        return promotionTip[0]
      } else {
        return promotionTip
      }
    }
  },
  methods: {
    ...mapMutations('cart', ['updateState']),
    handleFreeGift() {
      if (this.batchActive) return
      if ([4, 22].includes(this.promotionTypeId)) {
        this.updateState({
          key: 'addOnInfo', 
          value: {
            show: true,
            promotionId: this.promotionId,
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.free-gift-tip {
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  font-size: 24/75rem;
  min-height: 44/75rem;
  .tip-text {
    flex-shrink: 1;
    line-height: 28/75rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    word-break: normal;
    /deep/ em {
      color: #FA6338;
    }
    &.emphasize {
      color: #C44A01;
    }
  }
  .icon {
    flex-shrink: 0;
    margin-left: 4/75rem;
  }
}
</style>
