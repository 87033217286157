<template>
  <div
    v-if="showAttr"
    class="product-attr-ope"
  >
    <section class="editor-row">
      <div
        v-if="showSwitchToMall"
        v-expose="{ id: '1-8-3-77', data: { sku_code: item.product.sku_code } }"
        class="cart-item__editor cart-item__switch-mall"
        :class="{ disabled: batchActive}"
      >
        <div
          class="cart-item__editor-content"
          @click.stop="handleSwitchMall"
        >
          {{ switchToMallText }}
        </div>
      </div>
      <!-- reselect -->
      <div
        v-if="showReselect"
        class="cart-item__editor cart-item__editor-reselect"
        :class="{ disabled: batchActive || isCustomGood}"
      >
        <div
          class="cart-item__editor-content"
          @click.stop="handleEdit"
        >
          <div
            class="cart-item__editor-ellipsis"
          >
            <span tabindex="0">{{ language.SHEIN_KEY_PWA_16112 }}</span>
          </div>
          <Icon
            class="j-edit"
            aria-hidden="true"
            name="sui_icon_more_down_12px"
            size="12px"
            color="#222222"
          />
        </div>
      </div>
      <!-- find similar -->
      <div
        v-if="showFindSimilar"
        class="cart-item__editor cart-item__editor-similar"
        :class="{ disabled: batchActive || isCustomGood}"
      >
        <div
          class="cart-item__editor-content"
          :da-event-expose="batchActive? '' : '1-8-3-5'"
          :data-goods-id="item.product.goods_id"
          :data-sku="item.product.goods_sn"
          data-activeFrom="cart"
          :data-isOutOfStock="0"
          @click.stop="handleShowSimilar"
        >
          {{ language.SHEIN_KEY_PWA_16789 }}
        </div>
      </div>
  
      <!-- editor -->
      <div
        v-if="'editor' == editorStatus"
        class="cart-item__editor"
        :class="{ disabled: batchActive || isCustomGood}"
      >
        <div
          class="cart-item__editor-content"
          @click.stop="handleEdit"
        >
          <div
            class="cart-item__editor-ellipsis"
          >
            <div
              v-if="isShowColorImg"
              class="cart-item__editor-colorBox"
            >
              <img
                class="cart-item__editor-color"
                :src="transformImg({img: item.product.color_image})"
              />
            </div>
            <span
              class="cart-item__editor-trueSize"
              tabindex="0"
            >
              {{ skuSaleAttrText }}
            </span>
          </div>
          <Icon
            v-if="!isNoEdit"
            class="j-edit"
            aria-hidden="true"
            name="sui_icon_more_down_12px_2"
            size="12px"
            color="#222222"
          />
        </div>
      </div>

      <CartItemBehavior
        v-if="!isNewCartrowVision && (!doFilter + showFilterItem(item, filterType, targetCouponInfo, goodsFilterLabels))"
        ref="itemBehavior"
        class="item-behavior"
        :label="behaviorLabel"
        :language="language"
        :needSwipe="behaviorLabelNeedSwip"
        :product="item.product"
        :batch-active="batchActive"
        :is-first-page="isFirstPage"
      />
    </section>
    <!-- 隔开inline-block元素 -->
    <!-- 线上已经下线，下面代码不会再展示了 -->
    <div></div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { transformImg, template } from '@shein/common-function'
import SimilarInstance from 'public/src/pages/common/similar_product_modal/index.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { getImageRatio } from 'public/src/pages/goods_detail/utils/common.js'
import BehaviorLabel from 'public/src/pages/cartNew/utils/behaviorLabel.js'
import { cartEmphasizePromotionAll, scrollToCartItem } from '../../../utils'
import { Icon } from '@shein-aidc/icon-vue2'
import { showFilterItem } from '../../../utils/cartFilterLabels.js'
import { updateAttrMall } from '../../../utils/fetcher'

import CartItemBehavior from './CartItemBehavior.vue'
import { daEventCenter as daEventCenterV2 } from '@shein-aidc/basis-sa-event-center'

export default {
  name: 'CartItemEditor',
  components: {
    Icon,
    CartItemBehavior,
  },
  props: {
    index: {
      type: Number,
      default: 0
    },
    editorStatus: {
      type: String,
      default: ''
    },
    skuSaleAttrText: {
      type: String,
      default: ''
    },
    isAddBuyGoodsOrGiftGoods: {
      type: Boolean,
      default: false
    },
    isNoEdit: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    isShowColorImg: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default() {
        return {}
      }
    },
    isFirstPage: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapState('cart', [ 
      'language', 
      'blackFridayImgGoods', 
      'batchActive', 
      'cartAbtInfo', 
      'soldoutIds',
      'filterType',
      'targetCouponInfo',
      'goodsFilterLabels',
      'showIncentiveProductListDialog',
      'monitorUserInteraction',
      'siteMallList',
      'isShowCartFloorDrawer',
    ]),
    ...mapGetters('cart', ['cartList', 'doFilter', 'cartMallSwitchGoods', 'cartSaleAttrSwitchGoods', 'isNewCartrowVision']),
    showAttr(){
      return ('reselect' == this.editorStatus && !this.isAddBuyGoodsOrGiftGoods) || (['find-similar'].includes(this.editorStatus) || ['reselect'].includes(this.editorStatus)) || ('editor' == this.editorStatus)
    },
    behaviorLabel() {
      if (this.soldoutIds?.includes(this.item?.id)) return null
      const showBehaviorLabel = this.blackFridayImgGoods[this.item?.product?.goods_id]?.showBehaviorLabel
      return BehaviorLabel.getNormalLabel(showBehaviorLabel)
    },
    behaviorLabelNeedSwip() {
      return this.cartAbtInfo?.UserBehaviorTips?.param?.CartBehaviorAction == 'Show'
    },
    isCustomGood() {
      return Boolean(Object.keys(this.item.product?.customization_info || {}).length)
    },
    showReselect() {
      if ('reselect' !== this.editorStatus) return false
      if (this.showSwitchToMall) return false
      if (this.item.isSheinClubGiftItem && !this.item.isInvalid) return true // 未失效的付费会员赠品需要展示
      return !(this.isAddBuyGoodsOrGiftGoods || this.item.isLoseNewcomerItem)
    },
    isSwitchItem() {
      return (this.cartMallSwitchGoods && this.item.isMallSwitchGoods) || (this.cartSaleAttrSwitchGoods && this.item.isSaleAttrSwitchGoods)
    },
    showFindSimilar() {
      if (this.isSwitchItem) return false
      const list = []
      list.push('find-similar')
      if (!this.item.isSheinClubGiftItem) { // 付费会员赠品不展示 找相似
        list.push('reselect')
      }
      return list.includes(this.editorStatus)
    },
    showSwitchToMall() {
      return this.item.isMallSwitchGoods
    },
    switchToMallText() {
      if (this.showSwitchToMall) {
        const mallInfo = this.siteMallList.find(item => item.mall_code === this.item.prioritySwitchMall?.mall_code)
        return this.language.SHEIN_KEY_PWA_33152.replace('{0}', mallInfo?.language_mall_name ?? '')
      }
      return ''
    }
  },
  watch: {
    batchActive(val) {
      this.$refs?.itemBehavior?.changeAutoPlayStatus(!val)
    }
  },
  methods: {
    ...mapMutations('cart', ['updateState', 'modifyCheckedBeforeLoading', 'changeAppendageDrawerStatus', 'showLoading', 'hideLoading',]),
    ...mapActions('cart', ['asyncUpdateCartInfo', 'fetchModifyCartCheckStatus', 'handleFlashSaleOverScene']),
    transformImg,
    showFilterItem,
    async handleSwitchMall() {
      if (this.isBatchActivePage) return
      daEventCenter.triggerNotice({
        daId: '1-8-3-78',
        extraData: {
          sku_code: this.item.product.sku_code 
        }
      })
      const data = {
        id: this.item.id,
        goods_id: this.item.product.goods_id,
        quantity: Math.min(this.item.quantity, this.item.prioritySwitchMall.sku_qty),
        mall_code: this.item.prioritySwitchMall.mall_code,
        sku_code: this.item.product.sku_code,
        attrs: this.item.product.sku_sale_attr.filter(item => item.is_main == 0).map(item => ({
          attr_id: item.attr_id,
          attr_value_id: item.attr_value_id
        }))
      }
      this.showLoading()
      try {
        const res = await updateAttrMall(data)
        this.editComplete({ res })
      } catch {
        // 
      } finally {
        this.hideLoading()
      }
    },
    async handleEdit (extraParams = {}) {
      if( this.isCustomGood ) {
        this.$toast(this.language.SHEIN_KEY_PWA_26445)
        return
      }
      if (
        !(this.showReselect && this.item.isSheinClubGiftItem) &&
        (
          this.isNoEdit || // 付费会员赠品可编辑
          this.batchActive ||
          this.item.isNewcomerItem
        )
      ) return
      if (this.item.isSheinClubGiftItem) { // 当前非售罄会员赠品无法编辑
        this.changeAppendageDrawerStatus({
          status: 'clubGiftShow',
          promotionId: this.item.promotionTag.promotionId,
        })
        return
      }
      const { goods_id, sku_sale_attr = [] } = this.item.product
      const attrs = this.item.attrs ? JSON.parse(this.item.attrs) : ''
      let selectedAttrIndex = {}
      if(attrs) {
        attrs.forEach(item => {
          selectedAttrIndex[item.attr_id] = { attr_value_id: item.attr_value_id }
        })
      }
      sku_sale_attr && sku_sale_attr.forEach(item => {
        if(!item.is_main){
          selectedAttrIndex[item.attr_id] = { attr_value_id: item.attr_value_id }
        }
      })
      const imgRatio = await getImageRatio(this.item.product.goods_img)
      const getScene = () => {
        if (this.showIncentiveProductListDialog) {
          return 'benefit_pop'
        }
        if (this.isShowCartFloorDrawer) {
          return 'cart_floor_benefit_pop'
        }
        return 'cart'
      }
      daEventCenterV2.triggerNotice({
        id: 'click_goods_size_select.page_cart',
        extraData: {
          goods_status: !this.item.isSoldOutItem ? 'goods_status_available' : this.item.soldOutGoodsStatus,
          goods_id: this.item.product.goods_id,
        }
      })
      this.$quickAdd.open({
        goods_id,
        index: this.index,
        imgRatio,
        selectedAttrIndex: selectedAttrIndex,
        mallCode: this.item.mall_code,
        isShowEdit: true,
        isShowWishBtn: false,
        item_id: this.item.id,
        quantity: this.item.quantity,
        clickCallBack: {
          edit: this.editComplete,
          mallClick: this.changeMallClick,
          isActive: (show)=>{
            show && daEventCenter.triggerNotice({
              daId: '1-8-3-11',
              extraData: {
                sku_id: this.item.product.goods_sn,
                scene: getScene(),
              }
            })
          }
        },
        analysisConfig: {
          code: 'cart',
          from: 'cart',
          source: extraParams?.source || 'page',
        },
        showPromotionTagInfo: false,
      })
    },
    async editComplete ({ res }) {
      // 接入 apollo 控制是否调用 check 接口
      if(res.CART_AUTO_CHECKOUT !== 'ON' && (this.item.is_checked == 0 || (res.code == 0 && this.item.id != res.info.cart.id))){
        let appendIds = this.item.appendIds
        let reqParam = {
          operation_type: 1,
          cart_id_list: [],
          editCartId: res.info?.cart?.id || this.item.id
        }
        if (res.code == 0 && this.item.id != res.info.cart.id){
          let targetCartItem = res.info.info.carts?.find(cartItem => cartItem.id == res.info.cart.id)
          if(targetCartItem) {
            targetCartItem.is_checked == 0 && reqParam.cart_id_list.push(targetCartItem.id)
            appendIds = targetCartItem.appendIds
          }
        } else {
          reqParam.cart_id_list.push(this.item.id)
        }
        reqParam.cart_id_list = reqParam.cart_id_list.concat(appendIds || [])

        this.fetchModifyCartCheckStatus(reqParam)
      } else {
        if (res.code == 0) this.modifyCheckedBeforeLoading({ operation_type: 1, cart_id_list: [this.item.id] })
      }

      if ( res.code == 0 ) {
        let originCarts = JSON.parse(JSON.stringify(this.cartList))
        await this.asyncUpdateCartInfo({ cartInfo: res.info.info })
        if(this.item.id == res.info.cart.id || (this.item.id != res.info.cart.id)){
          this.handleFlashSaleOverScene({ editCartId: res.info.cart.id, carts: res.info.info?.carts, originCarts: originCarts })
        }
        if(this.item.mall_code != res.info.cart.mall_code || this.item.id != res.info.cart.id){
          this.$nextTick(() => {
            scrollToCartItem({ id: res.info.cart.id, smooth: true, rate: 1, timeout: 500, highlightAnimate: true })
          })
        }
        this.updateState({
          key: 'isReflashGetCouponFetch',
          value: true
        })
      } else if ( res.code == 300402 ) {
        this.$toast(this.language.SHEIN_KEY_PWA_15230)
      } else if ( [ 200401, 500301, 500302, 500306, 300417, 302444, 300714 ].includes(+res.code) ) {
        let errTxt = this.language.SHEIN_KEY_PWA_14967
        let p_id = ''
        if ( res.info && res.info.limitCount ) {
          errTxt = this.language.SHEIN_KEY_PWA_16138.replace('{0}', res.info.limitCount)
        }
        if ( [ 300714, 500302, 500306, 302444, 300417 ].includes(+res.code) ) {
          errTxt = res.tips.replace('{0}', res?.info?.limitCount)
          let proInfo = this.item.product.product_promotion_info && this.item.product.product_promotion_info.filter(promo => promo.type_id == 10 || promo.type_id == 11)
          p_id = proInfo[0] && +proInfo[0].promotion_id
          daEventCenter.triggerNotice({
            daId: '1-8-1-5'
          })
        }
        if([ 500302, 500306, 300417 ].includes(+res.code)) {
          errTxt = template(res?.info?.limitCount, res?.info?.resultLimit, res?.info?.remainNum, res.tips)
          this.$toast(errTxt, 4000)
        } else {
          this.showWarningInfo({
            text: errTxt,
            cb() {
              if ( p_id ) cartEmphasizePromotionAll([ p_id ], {
                shake: true,
                changeColor: true,
                scroll: true
              })
            }
          })
        }
      } else if ( res.code == 500303 ) {
        // let proInfo = this.item.product.product_promotion_info && this.item.product.product_promotion_info.filter(promo => promo.type_id == 10 || promo.type_id == 11)
        const p_id = this.item.promotionGroupId
        this.showWarningInfo( {
          text: template(res?.info?.limitCount, res?.info?.resultLimit, res?.info?.remainNum, res.tips),
          cb() {
            if ( p_id ) cartEmphasizePromotionAll( [ p_id ], {
              shake: true,
              changeColor: true,
              scroll: true
            })
            daEventCenter.triggerNotice({
              daId: '1-8-1-5'
            })
          }
        })
      } else if ( res.code == 500304 || res.code == 500305 ) {
        // 打折限购
        const proInfo = this.item.product.product_promotion_info && this.item.product.product_promotion_info.filter(promo => promo.typeId == 24)
        const id = proInfo[0] && +proInfo[0].promotion_id
        cartEmphasizePromotionAll( [ id ], { shake: true, changeColor: true, scroll: true })
      } else if ( res.code == 300405 ) {
        this.showWarningInfo( { text: res.info.limit_desc.replace('N', res.info.limit_count) })
      } else {
        this.$toast(res.tips)
      }
      this.monitorUserInteraction.stop({ reason: 'edit goods properties' })
    },
    showWarningInfo({ text = '', cb }){
      this.$toast(text)
      setTimeout(()=>{
        cb && cb()
      }, 1500)
    },
    changeMallClick(){
      daEventCenter.triggerNotice({
        daId: '1-8-3-12',
        extraData: {
          sku_id: this.item.product.goods_sn
        }
      })
    },
    handleShowSimilar(){
      if (this.batchActive) return
      SimilarInstance.showModal(this.item.product, {
        similarFrom: 'out_of_stock',
      })
      // 上报埋点
      daEventCenter.triggerNotice({
        daId: '1-8-3-6',
        extraData: {
          sku: this.item.product.goods_sn,
          goods_id: this.item.product.goods_id,
          activeFrom: 'cart',
          code: 'cart',
          isOutOfStock: ['find-similar'].includes(this.editorStatus) ? 1 : 0
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.product-attr-ope{
  padding-bottom: 0/75rem;
}
.editor-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .item-behavior {
    margin-bottom: 12/75rem;
  }
}

.cart-item__editor {
  flex-shrink: 0;
  min-width: 0;
  display: inline-block;
  max-width: 100%;
  background: #F6F6F6;
  padding: 6/75rem 12/75rem 6/75rem 16/75rem;
  border-radius: 78/75rem;
  font-size: 12px;
  height: 40/75rem;
  margin-right: 16/75rem;
  color: @sui_color_gray_dark2;
  margin-bottom: 12/75rem;
  &.disabled {
    opacity: .3;
    color: @sui_color_black;
    font-weight: bold;
  }
  &.cart-item__editor-similar{
    padding: 6/75rem 16/75rem 6/75rem 16/75rem;
    display: inline-flex;
    color: @sui_color_black;
    font-weight: bold;
    .cart-item__editor-content {
      top: 0
    }
  }
  &.cart-item__switch-mall {
    padding: 6/75rem 16/75rem 6/75rem 16/75rem;
    display: inline-flex;
    color: @sui_color_black;
    font-weight: 400;
    background: #FFFFFF;
    border: 1px solid #222222;
    .cart-item__editor-content {
      top: 0
    }
  }
  &.cart-item__editor-reselect{
    color: @sui_color_black;
    font-weight: bold;
  }
}
.cart-item__editor-content {
  line-height: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  top: 4/75rem;

  >div:not(:last-child) {
    margin-right: 4/75rem;
  }
}
.cart-item__editor-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.cart-item__editor-colorBox {
  position: relative;
  display: inline-block;
  margin-right: 4/75rem;
}
.cart-item__editor-color {
  .circle(22px);
  vertical-align: top;
  border-radius: 999px;
}
</style>
